import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import App from './App';
import FormElements from "./features/forms/components/FormElements.js";
import Meme from "./features/meme/components/Meme.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Meme />,
  }
]);

const Routes = () => <RouterProvider router={router} />;
export default Routes;
