import React, { useState, useRef, useEffect } from 'react';
import lottie from "lottie-web";
import { useDispatch, useSelector } from 'react-redux';

import data2 from "../duik-tests/dataDuik.json";
import _ from 'lodash';
// import DexScreenerLogo from "../styles/assets/dexscreener-logo.png";
// import XLogo from "../styles/assets/x-logo.png";
// import TelegramLogo from "../styles/assets/telegram-logo.png";

import DexScreenerLogo from "../styles/assets/dexscreener-logo-white.png";
import XLogo from "../styles/assets/x-logo-white.png";
import TelegramLogo from "../styles/assets/telegram-logo-white.png";


import data from "../lotties/data.json";
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import howToBuy from "../styles/assets/how-to-buy.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';



import deportationLogo from "../styles/assets-1/deporation-title.png"
import trumpWaving from "../styles/assets-1/trump-waving.png";
import wall from "../styles/assets-1/wall.png"
import logo from "../styles/assets-1/logo.png";
import "../styles/meme.scss"



export default function Meme(props) {
    const [elements, setElements] = useState([]);
    const elementsRef = useRef([]);

    const addElement = (name, params) => {
        setElements((prevElements) => {
            const newElement = {
                id: prevElements.length,
                type: params.type,
                name: name,
                state: {
                    ...params.styles
                },
            };
            elementsRef.current.push(React.createRef());
            return [...prevElements, newElement];
        });
    };


    const animationRendererRef = useRef();
    const mooDengHeroAnimationRendererRef = useRef();



    const lottieTestAnimationRendererRef = useRef();
    useEffect(() => {
        addElement("grandma", {
            type: "img",
            styles: {
                left: null,
                top: null
            },
            // range: animEnterRange,
            // animations: [
            //     { fn: "fixElToVPBottom", params: [currentScroll, grandmaEl] }
            // ]
        });

        addElement("background", {
            type: "img",
            styles: {
                left: null,
                top: null,
                width: "100%"
            }
        });

        addElement("text", {
            type: "text",
            styles: {
                left: null,
                top: null,
                opacity: 0
            }
        });




        const animation = lottie.loadAnimation({
            container: animationRendererRef.current,
            name: "animationRenderer",
            // preserveAspectRatio: "xMidYMid meet",
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: _.cloneDeep(data2)
        });



        const lottieTestAnimation = lottie.loadAnimation({
            container: lottieTestAnimationRendererRef.current,
            name: "lottieTestRenderer",
            // preserveAspectRatio: "xMidYMid meet",
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: _.cloneDeep(data)
        });



        // const mooDengHeroAnimation = lottie.loadAnimation({
        //     container: mooDengHeroAnimationRendererRef.current,
        //     name: "mooDengHeroAnimation",
        //     // preserveAspectRatio: "xMidYMid meet",
        //     renderer: 'svg',
        //     loop: true,
        //     autoplay: true,
        //     animationData: _.cloneDeep(mooDengHero)
        // });




        // addAnimation({
        //     range: animEnterRange,
        //     animations: [
        //         { fn: "fixElToVPBottom", params: [currentScroll, grandmaEl] }
        //     ]
        // });
    }, [])



    const containerRef = useRef();



    useEffect(() => {
        const preloadImage = (src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        };

        const initialize = async () => {
            try {
                // await preloadImage(Water);
                // await preloadImage(Grandma);
                // handleInitialLoad();
            } catch (error) {
                console.error('Failed to load image', error);
            }
        };

        initialize();
    }, []);



    const [contentOffset, setContentOffest] = useState(null);
    const [offsetSet, setOffsetSet] = useState(false); // New state to track if offset has been set


    const heroRef = useRef();
    const [isFixed, setIsFixed] = useState(window.innerWidth < 768); // State to toggle position between 'fixed' and 'absolute' based on viewport width
    function handleOnScroll(e) {
        const scrollPosition = e.target.scrollTop;
        const imageContainer = heroRef.current;

        const aspectRatio = 192 / 250; // Example aspect ratio (width/height)
        const imageHeight = window.innerWidth / aspectRatio; // Calculate the image height based on the viewport width and aspect ratio

        if (window.innerWidth < 768) { // Check if the viewport width is less than 768px (mobile)
            // Set the scroll trigger point to 20% from the bottom of the image
            const scrollTriggerPoint = imageHeight - (imageHeight * 0.4);

            // console.log("imageContainer", scrollPosition, imageHeight, scrollTriggerPoint, e.target.clientHeight - scrollTriggerPoint)

            if ((scrollPosition >= e.target.clientHeight - scrollTriggerPoint)) {

                setIsFixed(false); // Change to absolute when the trigger point is reached
                // Only set contentOffset if it hasn't been set before
                if (!offsetSet) {
                    setContentOffest(containerRef.current.scrollTop);
                    setOffsetSet(true); // Mark offset as set
                }
            } else {
                setOffsetSet(false);
                setIsFixed(true); // Keep fixed before the trigger point
            }
        } else {
            // Set the scroll trigger point to 50% of the image
            const scrollTriggerPoint = imageHeight / 2;

            if (scrollPosition >= scrollTriggerPoint) {
                setIsFixed(true); // Change to fixed when the trigger point is reached
            } else {
                setIsFixed(false); // Keep absolute before the trigger point
            }
        }


    }



    let callback = (entries, observer) => {
        entries.forEach((entry) => {
            // Each entry describes an intersection change for one observed
            // target element:
            //   entry.boundingClientRect
            //   entry.intersectionRatio
            //   entry.intersectionRect
            //   entry.isIntersecting
            //   entry.rootBounds
            //   entry.target
            //   entry.time

            const targetElement = entry.target;


            // console.log("entry.boundingClientRect.top ", entry.boundingClientRect.top, entry)


            const fromTop = entry.intersectionRect.top;
            const fromBottom = entry.rootBounds.height - entry.intersectionRect.bottom;

            console.log("fromTop", fromTop, fromBottom)

            if (fromTop > fromBottom && entry.isIntersecting === true) {
                console.log('Element is scrolling in from bottom!');
            }

            if (fromBottom > fromTop && entry.isIntersecting === true) {
                console.log('Element is scrolling in from Top!');
            }


            if (targetElement === lottieTestAnimationRendererRef.current) {
                if (entry.isIntersecting) {
                    // lottieTestAnimationRendererRef.current

                    const registeredAnimations = lottie.getRegisteredAnimations();
                    const registeredAnimation = registeredAnimations[registeredAnimations.length - 1];
                    // registeredAnimation.goToAndStop(registeredAnimation.totalFrames);
                    console.log("registeredAnimation", registeredAnimation)
                    // registeredAnimation.goToAndStop(0)
                    // registeredAnimation.play()
                }
                const registeredAnimations = lottie.getRegisteredAnimations();
                const registeredAnimation = registeredAnimations[registeredAnimations.length - 1];
                registeredAnimation.goToAndStop(0)
                registeredAnimation.play()


                const currentY = entry.boundingClientRect.y
                const currentRatio = entry.intersectionRatio
                const isIntersecting = entry.isIntersecting


                // // Scrolling down/up
                // if (currentY < previousY) {
                //     if (currentRatio > previousRatio && isIntersecting) {
                //         console.log("Scrolling down enter");
                //     } else {
                //         console.log("Scrolling down leave");
                //     }
                // } else if (currentY > previousY && isIntersecting) {
                //     if (currentRatio < previousRatio) {
                //         console.log("Scrolling up leave");
                //     } else {
                //         console.log("Scrolling up enter");
                //     }
                // }

                // setPreviousY(currentY);
                // setPreviousRatio(currentRatio);

            }

            if (entry.isIntersecting) {
                // setItemClass("opacity");
            } else {
                // setItemClass("");
            }
        });
    };


    useEffect(() => {
        const options = {
            // root: containerRef.current, // Use the viewport as the root
            root: null, // Use the viewport as the root
            rootMargin: '0px 0px -200px', // Expand the viewport by 100px on all sides
            // threshold: 1 // Trigger when any part of the element is visible
        };

        const observer = new IntersectionObserver(callback, options);
        // const targetElement = document.querySelector("selector");



        // observer.observe(lottieTestAnimationRendererRef.current);
    }, [])


    const [someState, setSomeState] = useState('');



    const [copyState, setCopyState] = useState({
        value: '9829KQaxuFRjy8aX7EsSwH7ogJpS4dh8BBGfUKUMdiDf',
        copied: false
    })


    let heroStyles = {};
    let navbarStyles = {};

    // console.log("containerRef.current.scrollTop", containerRef.current ? containerRef.current.scrollTop : "")

    if (window.innerWidth < 768) { // Check if the viewport width is less than 768px (mobile)
        // console.log("contentOffset.current", contentOffset);
        heroStyles = { position: isFixed ? 'fixed' : 'absolute', top: isFixed ? '0' : `${contentOffset}px` }
        navbarStyles = { position: isFixed ? 'fixed' : 'absolute', top: isFixed ? '0' : `${contentOffset}px` }
    } else {
        if (containerRef.current) {
            // console.log("containerRef.current.scrollTop", containerRef.current.scrollTop)
            navbarStyles = { position: 'absolute' }
            // heroStyles = { position: isFixed ? 'fixed' : "absolute", top: isFixed ? `${containerRef.current.scrollTop}px` : 0}

            // heroStyles = { position: isFixed ? 'fixed' : 'absolute', top: isFixed ? '0' : `${containerRef.current.scrollTop}px` }

            // heroStyles = { position: isFixed ? 'sticky' : 'absolute', top: isFixed ? '-50%' : `${containerRef.current.scrollTop}px` }

            // heroStyles = { position: 'sticky', top: '-150%' }

            // heroStyles = { position: isFixed ? 'st' : "absolute", top: isFixed ? `${containerRef.current.scrollTop}px - ` : 0}

            // heroStyles = { position: "absolute", top: `${containerRef.current.scrollTop}px` }
        }
    }


    return (
        <div ref={containerRef} className="meme" onScroll={handleOnScroll} >
            <div style={navbarStyles} className="meme-navbar">
                <div className="socials">
                    {/* <div className="socials__logo-container">
                            <img src={DexScreenerLogo} alt="DexScreenerLogo" />
                        </div> */}
                    <div className="socials__logo-container">
                        <a href="https://x.com/deportation_sol" target="_blank">
                            <img src={XLogo} alt="XLogo" />
                        </a>
                    </div>
                    <div className="socials__logo-container">
                        <a href="https://t.me/deportationtoken" target="_blank">
                            <img src={TelegramLogo} alt="TelegramLogo" />
                        </a>

                    </div>
                </div>
                <a className="deportation-btn" href="https://pump.fun/coin/9829KQaxuFRjy8aX7EsSwH7ogJpS4dh8BBGfUKUMdiDf" target="_blank">
                    <button>Buy $DEPORTATION</button>
                </a>
            </div>
            <div className="section first hero" ref={heroRef} style={heroStyles}>
                <div className="hero__bg"></div>

                {/* v<div className="deportation-logo"><img src={deportationLogo}></img></div>
                <div className="trump-waving">
                    <img src={trumpWaving} atl="trumpWaving" />
                </div> */}

                {/* <div className="main-graphics">
                    <div className="moo-deng-hero-animation-renderer" ref={mooDengHeroAnimationRendererRef}></div>
                </div> */}
            </div>


            <div className="scrollable-content">
                {window.innerWidth < 800 && (
                    <div className="wall">
                        <img src={wall} alt="wall" />
                    </div>
                )}
                <div className="frame-section">
                    <div className="address">
                        <p className="address__ca">CA:</p>
                        <p className="address__token-address">Token Address:</p>
                        <p className="address__value">{copyState.value}</p>
                        <CopyToClipboard text={copyState.value}
                            onCopy={() => setCopyState({ ...copyState, copied: true })}>
                            <button><FontAwesomeIcon icon={faCopy} />Copy address</button>
                        </CopyToClipboard>
                        {/* {copyState.copied ? <span>Copied.</span> : null} */}

                    </div>
                </div>
                <div className="section second">

                </div>
                <div className="section third">

                </div>
                <div className="section fourth">=
                    {/* <div className="how-to-buy">
                        <div className="lottie-test-animation-renderer" ref={lottieTestAnimationRendererRef}></div>

                        <div className="how-to-buy__steps">
                                <img src={createAWallet} alt="createAWallet" />
                                <img src={addSolana} alt="addSolana" />
                                <img src={buyToken} alt="buyToken" />
                                <img src={holdUntilRich} alt="holdUntilRich" />
                            </div>
                    </div> */}
                    <div className="section fifth">
                        <div className="copyright">
                            <div>
                                Copyright © 2024 DEPORTATION - All Rights Reserved.
                                <p>Disclaimer: DEPORTATION is a meme token with no intrinsic value. Don’t risk money if you’re not prepared of losing investment. <br />The price can rise or fall. We are not responsible for the token's price movements.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
