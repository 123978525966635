
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sidebarOpen: false,
    activePanel: 1,
    ui: {
    }
};

export const layout = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleSidebar: (state, action) => {
            state.sidebarOpen = !state.sidebarOpen;
        },
        switchActivePanel: (state, action) => {
            if (state.activePanel === 4) {
                state.activePanel = 1;
            } else {
                state.activePanel = state.activePanel + 1;
            }
        },
        setActivePanel: (state, action) => {
            const { activePanelIndex } = action.payload;

            state.activePanel = activePanelIndex;
        },
    },
    extraReducers: (builder) => {

    },
});

export const {
    toggleSidebar,
    switchActivePanel,
    setActivePanel
} = layout.actions;

export const selectActivePanel = (state) => {
    return state.layout.activePanel;
}

export const selectSidebarState = (state) => {
    return state.layout.sidebarOpen;
}

export default layout.reducer;
