import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/example-redux/counter/counterSlice';
import layoutReducer from "../features/layout/slices/layoutSlice";


const jwt = localStorage.getItem("JWT");

const initialState = {
  auth: {
    jwt
  }
};

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    layout: layoutReducer,
  },
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
