import React from 'react';
import { Formik, Form, } from "formik";
import * as Yup from "yup";
import Input from './Input';
import Textarea from './TextArea';
import Select from './Select';
import RadioButtons from './RadioButtons';
import CheckboxGroup from './CheckboxGroup';
import SubmitButton from './SubmitButton';
import '../styles/form-elements.scss';

const initialValues = {
  textInput: "",
  email: "",
  password: "",
  textarea: "",
  selectOption: "",
  radioOptions: "",
  checkboxOption: [],
  datePicker: null
};

const onSubmit = (values, onSubmitProps) => {
  console.log("VALUES:", values);
};


const validationSchema = Yup.object({
  textInput: Yup.string().required("*This field is required"),
  email: Yup.string().email("*Invalid email format").required("*This field is required"),
  password: Yup.string().required("*This field is required"),
  textarea: Yup.string().required("*This field is required"),
  selectOption: Yup.string().required("*This field is required"),
  radioOptions: Yup.string().required("*This field is required"),
  checkboxOption: Yup.array().min(1, "*This field is required"),
  datePicker: Yup.string().required("*This field is required"),
})


export default function FormElements(props) {
  const dropdownOptions = [
    { key: "Select an option", value: "" },
    { key: "Option 1", value: "option1" },
    { key: "Option 2", value: "option2" },
    { key: "Option 3", value: "option3" },
  ];

  const radioOptions = [
    { key: "Radio Option 1", value: "rOption1" },
    { key: "Radio Option 2", value: "rOption2" },
    { key: "Radio Option 3", value: "rOption3" },
  ];

  const checkboxOptions = [
    { key: "Checkbox Option 1", value: "cOption1" },
    { key: "Checkbox Option 2", value: "cOption2" },
    { key: "Checkbox Option 3", value: "cOption3" },
  ]

  return (
    <div>
      <h2>Example Form</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        <Form className="form-elements">
          <Input name="textInput" placeholder="Input some text" label="Text Input" />
          <Input name="email" placeholder="Input an email" label="Email Input" type="email" />
          <Input name="password" placeholder="Input a password" label="Password Input" type="password" />
          <Textarea name="textarea" rows={10} placeholder="Input long text" label="Text Area" />
          <Select name="selectOption" label="Select an option" options={dropdownOptions} />
          <RadioButtons name="radioOptions" label="Select radio option" options={radioOptions} />
          <CheckboxGroup name="checkboxOption" label="Checkbox options" options={checkboxOptions} />
          <SubmitButton />
        </Form>
      </Formik>
    </div>
  );
}


// With disabled submit button
// https://www.youtube.com/watch?v=F69AlPc0O8o&list=PLC3y8-rFHvwiPmFbtzEWjESkqBVDbdgGu&index=27
// return (
//   <Page>
//     <h2>Example Form</h2>
//     <Formik
//       initialValues={initialValues}
//       onSubmit={onSubmit}
//       validationSchema={validationSchema}>
//       {(formik) => {
//         return (
//           <Form className="form-elements">
//             <Input name="textInput" placeholder="Input some text" label="Text Input" />
//             <Input name="email" placeholder="Input an email" label="Email Input" type="email" />
//             <Input name="password" placeholder="Input a password" label="Password Input" type="password" />
//             <Textarea name="textarea" rows={10} placeholder="Input long text" label="Text Area" />
//             <Select name="selectOption" label="Select an option" options={dropdownOptions} />
//             <RadioButtons name="radioOptions" label="Select radio option" options={radioOptions} />
//             <CheckboxGroup name="checkboxOption" label="Checkbox options" options={checkboxOptions} />
//             <DatePicker name="datePicker" label="Date Picker" />
//             <button type="submit" disabled={!formik.isValid || formik.isSubmitting}>Submit</button>
//           </Form>
//         )
//       }}
//     </Formik>
//   </Page>
// );